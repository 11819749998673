// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-page-child-carpets-js": () => import("./../src/templates/page-child-carpets.js" /* webpackChunkName: "component---src-templates-page-child-carpets-js" */),
  "component---src-templates-page-child-vinyl-flooring-js": () => import("./../src/templates/page-child-vinyl-flooring.js" /* webpackChunkName: "component---src-templates-page-child-vinyl-flooring-js" */),
  "component---src-templates-page-parent-js": () => import("./../src/templates/page-parent.js" /* webpackChunkName: "component---src-templates-page-parent-js" */)
}

